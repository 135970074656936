<template>
    <div v-if="addFlag2" :class="[userid? '': 'mask']">
        <div class="" :class="[userid? '': 'slider']">
            <div v-if="(!userid)" class="slider_close" @click="hideMask"><img src="@/assets/imgs/shut.png" alt=""></div>
            <div v-if="(!userid)" class="title">新建联系人 <img class="title_close" src="@/assets/imgs/close.png" alt="" @click="hideMask"></div>
            <div class="content">
                <div class="content_row content_detail" v-for="(item,index) in data" :key="index">
                    <template v-if="item.iswrite == 1">
                        <label class="info" for="" style="line-height:32px">{{item.text}}:</label>
                        <!-- 数字 -->
                        <div class="content_detail_right" v-if="item.type == 'number'">
                            <a-input type="number" v-model="item.value"></a-input>
                        </div>
                        <!-- 单行文本 -->
                        <div class="content_detail_right" v-if="item.type == 'SingleText' && item.name != 'tmjcpb_corp__name'">
                            <a-input v-model="item.value"></a-input>
                        </div>
                        
                        <!-- 多行文本 -->
                        <div class="content_detail_right" v-else-if="item.type == 'multiText'">
                            <a-textarea v-model="item.value">{{item.value}}</a-textarea>
                        </div>
                        <!-- 单选标签 -->
                        <div class="content_detail_right" v-else-if="item.type == 'radioTag'">
                            <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                            <!-- <a-radio-group v-model="item.value">
                                <a-radio class="radioTag" v-for="(option,index2) in item.options" :key="index2" :value="option.value">
                                    {{option.value}}
                                </a-radio>
                            </a-radio-group> -->
                            <a-radio-group v-model="item.value" button-style="solid">
                                <a-radio-button class="radioTag" v-for="(option,index2) in item.options" :key="index2" :value="option.value">
                                    {{option.value}}
                                </a-radio-button>
                            </a-radio-group>
                        </div>  
                        <!-- 多选标签 -->
                        <div class="content_detail_right" v-else-if="item.type == 'checkTag'">
                            <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                            <a-checkbox-group v-model="item.value" :options="item.options">
                                <span slot="label" slot-scope="{ value }">{{ value }}</span>
                            </a-checkbox-group>
                        </div>
                        <!-- 时间 
                        <div class="content_detail_right" v-else-if="item.type == 'time'">
                            <a-time-picker v-model="item.value" /> 
                        </div>-->
                        <!-- 日期 -->
                        <div class="content_detail_right" v-else-if="item.type == 'date'">
                            <a-date-picker v-model="item.value" /> 
                        </div>
                        <!-- 日期时间 -->
                        <div class="content_detail_right" v-else-if="item.type == 'dateTime'">
                            <a-date-picker show-time v-model="item.value" />
                        </div>
                        <!-- 下拉框 -->
                        <div class="content_detail_right" v-else-if="item.type == 'select'">
                            <a-select size="large" v-model="item.value"> 
                                <a-select-option  v-for="(option,index2) in item.options" :key="index2" :value="option.value">{{option.value}}</a-select-option>
                            </a-select>
                        </div>
                        <!-- 下拉框 多选-->
                        <div class="content_detail_right" v-else-if="item.type == 'selects'">
                            <a-select size="large" mode="multiple" v-model="item.value"> 
                                <a-select-option v-for="(option,index2) in item.options" :key="index2" :value="option.value">{{option.value}}</a-select-option>
                            </a-select>
                        </div>
                        <!-- 附件-->
                        <div class="content_detail_right" v-else-if="item.type == 'annex'">
                            <a :href="url + '/scrm/index/Download?id='+ item.value" v-if="item.value">{{item.value2}}</a>
                            <label class="fileLabel upFile" for="annex"><a-icon type="upload" /> 上传 </label>
                            <input type="file" id="annex" value="" @change="annexUpload($event,index)">
                        </div>
                        <!-- 单张图 -->
                        <div class="content_detail_right" v-else-if="item.type == 'image'">
                            <label v-if="item.value" class="fileLabel" for="image"><img :src="url + '/scrm/index/getFile?id=' + item.value" style="margin-bottom:10px"></label>
                            <label class="fileLabel" for="image"><a-icon type="upload" /> 上传 </label>
                            <input type="file" id="image" value="" @change="imgUpload($event,index)">
                        </div>
                        <!-- 多张图-->
                        <div class="content_detail_right" v-else-if="item.type == 'images'">
                            <img  v-if="item.value" :src="url + '/scrm/index/getFile?id=' + item.value" style="margin-bottom:10px">
                            <label class="fileLabel" for="image"><a-icon type="upload" /> 上传 </label>
                            <input type="file" id="image" value="" @change="imgUpload($event,index)">
                        </div>
                        <!-- 地区-->
                        <div class="content_detail_right" v-else-if="item.type == 'city'">
                            <a-cascader placeholder="请选择地区" :options="citys" v-model="item.value" />
                        </div>
                    </template>
                    <!-- 输入框 -->
                    <!-- <a-input size="large" v-if="item.type == 'SingleText'" :placeholder="'请输入客户的'+item.text" v-model="form[item.name]"/>

                    <a-input size="large" v-if="item.name == 'tmjcpb_corp__name' && item.type == 'SingleText'" placeholder="" v-model="form[item.name]" @input="handleChange"/>
                    <div v-if="item.name == 'tmjcpb_corp__name' && nameList.length != 0">
                        <a-list size="small" bordered>
                            <a-list-item v-for="(item2,index2) in nameList" :key="index2" @click="checkName(item2)">
                                {{ item2["tmjcpb_corp__name"] }}
                            </a-list-item>
                        </a-list>
                    </div> -->
                    
                    <!-- 单选框 -->
                    <!-- <a-radio-group v-else-if="item.type == 'select'" :name="item.name">
                        <a-radio-button v-for="(item1,index1) in item.options" :value="item1" :key="index1">{{item1}}</a-radio-button>
                    </a-radio-group> -->
                    <!-- <a-radio-group v-else-if="item.type == 'select'" :options="plainOptions" :default-value="value1" @change="onChange1" /> -->
                    <!-- 下拉框 -->
                    <!-- <a-select size="large" v-else-if="item.type == 'radio' && item.options != null" v-model="form[item.name]" >
                        <a-select-option v-for="(item1,index1) in item.options" :key="index1" :value="item1">{{item1}}</a-select-option>
                    </a-select> -->
                    <!-- 标签 -->
                    <!-- <div v-else-if="item.type == 'tag'">
                        <a-tag v-for="(item1,index1) in item.options" :key="index1" size="large" color="blue">blue</a-tag>
                    </div> -->
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
        </div>
    </div>
</template>
<script>
import {requestXml,fileUpload,ajaxUrl} from '../assets/js/request';
import "../assets/css/client.css";
import citys from '@/assets/js/city.js';
export default {
    props: {
        addFlag2: {
            type: Boolean,
            default: false
        },
        userid: {
            type: String,
        },
        corpid: {
            type: String,
        },
        clientId: {
            type: String,
        }
    },
    watch:{
        userid: function(val){
            this.linkmanUserid = val;
            if(val){
                this.addFlag2 = true;
            }
        },
        corpid: function(val){
            this.id = val;
        },
        clientId: function(val){
            this.id = val;
        },
    },
    data() {
        return {
            url: ajaxUrl,
            citys,
            data: [],
            form: {},
            //客户id
            id: 0,
            // 联系人id
            lid: 0,
            linkmanUserid: "",
        };
    },
    mounted: function(){
        for(let i=0;i<citys.length;i++){
            citys[i].value = citys[i].label
            for(let j=0;j<citys[i].children.length;j++){
                citys[i].children[j].value = citys[i].children[j].label
                for(let k=0;k<citys[i].children[j].children.length;k++){
                    citys[i].children[j].children[k].value = citys[i].children[j].children[k].label
                }
            }
        }
        this.addLinkman();
    },
   
    methods: {
        getDetail(id){
            this.lid = id;
            requestXml("/scrm/Linkman/getDetails","POST",(res) => {
                // console.log(res)
                this.data = res;
            },{"lid":id})
        },
        
        hideMask(){
            this.lid = "";
            this.$emit('updateAddFlag2', false);
        },
        addLinkman(){
            // 获取联系人添加功能需要填写字段
            requestXml("/scrm/Linkman/getField","GET",(res) => {
                // console.log(res,"resres")
                this.data = res;
            })
        },
        // 附件
        annexUpload(e,index){
            fileUpload(e,(res) => {
                this.data[index].value = res.id;
                this.data[index].value2 = res.name;
            })
        },
        // 单张图
        imgUpload(e,index){
            console.log(e,index)
            fileUpload(e,(res) => {
                this.data[index].value = res.id;
                this.data[index].value2 = res.path;
               
            })
        },
        // 添加联系人
        submit(){
            let corp = this.data;
            let corpObj = {};
            let url = "";
            if(this.linkmanUserid){
                corpObj.tmjcpb_linkman__openid = this.linkmanUserid;
                url = "/scrm/Linkman/add";
            }
            for(let i=0;i<corp.length;i++){
                if(corp[i].value && corp[i].iswrite == 1){
                    if(corp[i].type == 'date' || corp[i].type == 'dateTime'  || corp[i].type == 'time'){
                        console.log(corp[i].value._d)
                        corp[i].value = corp[i].value._d;
                    }
                    if(corp[i].type == 'selects' && corp[i].value.length != 0){
                        corp[i].value = corp[i].value.join(",");
                    }
                    if(corp[i].type == 'checkTag' && corp[i].value.length != 0){
                        corp[i].value = corp[i].value.join(",");
                    }
                    if(corp[i].type == 'city' && corp[i].value.length != 0){
                        corp[i].value = corp[i].value.join(",");
                    }
                    corpObj[corp[i].name] = corp[i].value;
                    if(this.lid){
                        corpObj['lid'] = this.lid;
                        url = "/scrm/Linkman/up";
                    }else{
                        corpObj['tmjcpb_linkman__ids'] = this.id;
                        url = "/scrm/Linkman/add";
                    }
                }
            }
            requestXml(url,"POST",(res) => {
                if(res == "success"){
                    if(this.linkmanUserid){
                        this.$router.go(-1);
                    }else{
                        this.addLinkman();
                        this.$emit('updateAddFlag2', false);
                        // this.$emit('updateLinkmanList');
                    }
                }
            },corpObj)
        }
    }
};
</script>
<style>
    /* .mask .slider .content .content_row{
        margin: 2rem 0;
    }
    .mask .slider .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .mask .slider .content .content_row .content_detail_right{
        width: calc(100% - 17rem);
        border-radius: 4px;
    } */
    /* 性别选择 */
    /* .mask .slider .content .content_row .ant-radio-button-wrapper{
        width: 9rem;
        margin-right: 2.5rem;
        border-radius: 4px;
        border: 1px solid #91d5ff;
        color: #1890ff;
        background: #e6f7ff;
        display: inline-block;
        text-align: center;
    }
    .mask .slider .content .content_row .ant-radio-button-wrapper:last-child{
        color: #fa8c16;
        background: #fff7e6;
        border-color: #ffd591;
    }
    .mask .slider .content .content_row .ant-radio-button-wrapper:not(:first-child)::before{
        width: 0;
    } */
    /* 日期插件，下拉框 */
    /* .mask .slider .content .date .ant-calendar-picker,.mask .slider .content .content_row .ant-select{
        width: calc(100% - 16rem);
    }
    .mask .slider .content .date .ant-calendar-picker input{
        width: 100%;;
    } */
    /*.mask .slider .content .content_row .ant-select-search--inline input{
         border: 2px solid red; */
        /* width: 100%;
    } */
    
    /* .ant-list li{
        cursor: pointer;
    } */
   
    /* 标签 */
    /* .mask .slider .content .content_row .ant-tag{
        width: 11rem;
        height: 5rem;
        line-height: 5rem;
        text-align: center;
    } 
    @media screen and (min-width: 750px){
        .mask .slider .content .content_row{
            font-size: 16px;
        }
        .mask .slider .content .content_row .info{
            width: 110px;
            padding-right: 10px;
        }
        
        .mask .slider .content .company{
            width: calc(100% - 150px);
            position: absolute;
            z-index: 9;
            right: 15px;
            background: #fff;
        }
    } */
</style>
